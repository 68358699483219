<style lang="less" scoped>
  .wap-doc-step-page {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  .block {
    background-color: #FFF;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    & + .block {
      margin-top: 10px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    & + .row {
      margin-top: 10px;
    }
  }
  .field {
    width: 70px;
    text-align: right;
  }
</style>

<template>
  <div class="wap-doc-step-page">
    <div class="block row" style="justify-content: space-between; align-items: center;">
      <fm-title title-text="安排人员"></fm-title>
      <fm-btn @click="save">保存</fm-btn>
    </div>
    <div style="margin: 10px 0;flex: 1;overflow:auto;">
      <div v-for="(item, index) in dataList.filter(v => !v.isDel)" :key="index" class="block">
        <div class="row">
          <span class="field">环节：</span>
          <div class="row" style="justify-content: space-between;flex: 1;">
            <span>{{index + 1}}</span>
            <fm-btn style="margin-left: 10px;" @click="del(item)" size="small">删除</fm-btn>
          </div>
        </div>
        <div class="row">
          <span class="field">名称：</span>
          <fm-input-new v-model="item.type" style="flex: 1;" />
        </div>
        <div class="row">
          <span class="field">参与人：</span>
          <fm-select style="flex: 1;" size="norm" multiple absolute filterable :clearable="true" v-model="item.workerIds">
            <fm-option v-for="v in workList" :key="v.key" :value="v.key" :label="v.label"></fm-option>
          </fm-select>
        </div>
      </div>
    </div>
    <div class="block">
      <fm-btn block @click="addStep">添加</fm-btn>
    </div>
  </div>
</template>

<script>
import { officialDocumentRequest } from '../../../api'

export default {
  data () {
    return {
      stepList: [],
      docData: {},
      dataList: [],
      fileIds: [],
      typeList: ['拟办', '批示', '办理结果', '传阅']
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    workList () {
      return this.$store.getters.allWorkerList
    }
  },
  methods: {
    dealdata (hisData, newData) {
      let data = []
      let i = 0
      while (i < hisData.length) {
        let hisItem = hisData[i]
        let newItem = newData.find(v => v.id === hisItem.id)
        if (newItem.isDel) {
          hisItem.isDel = true
        } else {
          hisItem.isDel = false
          hisItem.type = newItem.type
          let workerIds = newItem.workerIds.filter(id => id)
          hisItem.handles.forEach(v => {
            if (!workerIds.includes(v.workerId)) {
              v.isDel = true
            } else {
              v.isDel = false
            }
            workerIds = workerIds.filter(id => id !== v.workerId)
          })
          workerIds.forEach(workerId => {
            hisItem.handles.push({
              workerId: workerId,
              isDel: false
            })
          })
        }
        data.push(hisItem)
        i += 1
      }
      newData.filter(v => String(v.id).includes('id_') && !v.isDel).forEach(v => {
        data.push({
          type: v.type,
          handles: (v.workerIds || []).map((id) => {
            return {
              workerId: id
            }
          })
        })
      })
      data.forEach(v => v.handleList = v.handles)
      return data
    },
    async save () {
      let parm = Object.assign({}, this.docData, {
        fileIds: this.fileIds.length > 0 ? this.fileIds.join(',') : null,
        sendTime: this.docData.sendTime && this.docData.sendTime instanceof Date ? this.$datetime.format(this.docData.sendTime, 'Y-M-D') : this.docData.sendTime,
        stepList: this.dealdata(JSON.parse(JSON.stringify(this.docData.steps || [])), this.dataList)
      })
      await officialDocumentRequest.add(parm)
      this.$notice.success('保存成功')
      setTimeout(() => { this.$router.back() }, 1000)
    },
    del (item) {
      const index = this.dataList.findIndex(v => item === v)
      if (String(this.dataList[index].id).includes('id_')) {
        this.dataList[index] = null
        this.dataList = this.dataList.filter(v => v)
      } else {
        this.dataList[index].isDel = true
      }
    },
    addStep () {
      this.dataList.push({
        id: 'id_' + new Date().getTime(),
        isDel: false,
        type: '',
        workerIds: []
      })
    },
    async loadData () {
      await this.$store.dispatch('loadAllWorkerList')
      if (this.id) {
        let datas = await officialDocumentRequest.getDetail({
          officialDocumentId: this.id
        })
        if (datas.length > 0) {
          this.docData = datas[0]
          this.stepList = this.docData.steps || []
          this.fileIds = this.docData.fileIds ? this.docData.fileIds.split(',').filter(v => v).map(v => Number(v)) : []
        }
      } else {
        this.docData = {}
        this.stepList = []
      }
      this.loadStep()
    },
    loadStep () {
      let data = []
      if (this.stepList && this.stepList.length > 0) {
        data = this.stepList.map((v) => {
          return {
            id: v.id,
            isDel: v.isDel,
            type: v.type,
            workerIds: (v.handles || []).map(v => v.workerId)
          }
        })
      } else {
        data = this.typeList.map((v, index) => {
          return {
            id: 'id_' + index,
            isDel: false,
            type: v,
            workerIds: []
          }
        })
      }
      this.dataList = data
    }
  },
  activated () {
    if (!this.$route.query.id) {
      this.$router.back()
    } else {
      this.loadData()
    }
  }
}
</script>
